<template>
    <div id='contact-section'>
        <h3 class='contact section-subtitle'>
            {{ $t("CONTACT.TITLE") }}
        </h3>
        <a href='mailto:ivandiaz.lara.m@gmail.com' class='contact-email'>
            {{ $t("CONTACT.EMAIL") }}@gmail.com
        </a>
    </div>

    <footer class="footer columns">
        <div v-if='!isMobile' class="social-networks-icons column is-half">
            <a href="https://www.linkedin.com/in/ivan-diaz-lara-550488213/" target="_blank" rel="noopener noreferrer">
                <i class="fab fa-linkedin fa-2xl"></i>
            </a>
            <a href="https://github.com/IvanDLar" target="_blank" rel="noopener noreferrer">
                <i class="fab fa-github fa-2xl"></i>
            </a>
        </div>
        <div class="footer-tools column is-half">
            <p class='footer-build-description'> {{ $t("CONTACT.DESIGN") }} <a href='https://www.figma.com/'>Figma</a>. {{ $t("CONTACT.FRONT_END") }} <a href='https://vuejs.org/'>Vue.js</a> {{ $t("CONTACT.AND") }} <a href='https://bulma.io/'>Bulma.css</a>, {{ $t("CONTACT.DEPLOYMENT") }} <a href='https://vercel.com/'>Vercel</a>. {{ $t("CONTACT.BACK_END") }} <a href='https://nodejs.org/en'>Node.js</a>, <a href='https://expressjs.com/'>Express.js</a>, {{ $t("CONTACT.AND") }} {{ $t("CONTACT.THE") }} <a href='https://openai.com/'>OpenAI API</a>, {{ $t("CONTACT.VERCEL_DEPLOYMENT") }}.</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'ContactFooter',
    computed: {
        // Make a function that returns true when the display is smaller than 768px.
        isMobile() {
            return window.innerWidth < 768;
        }
    }
};
</script>

<style scoped>
.footer {
    background-color: transparent; 
    padding-bottom: 50px;
    align-items: center;
}

.footer-tools {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.footer-build-description {
    opacity: 0.9;
}

.contact-email {
    position: relative;
    font-weight: bold;
    font-size: 20px;
    z-index: 0;
    transition: transform 0.3s ease-in-out;
}

.contact-email::after {
    content: "";
    position: absolute;
    width: 102%;
    height: 100%;
    background: color-mix(in srgb, var(--primary-color), transparent 50%);
    transform: scaleY(0.2);
    transform-origin: bottom;
    bottom: 13.5%;
    left: -1%;
    transition: transform 0.3s ease-in-out; 
    z-index: -1;
    opacity: 0.75;
}

.contact-email:hover {
    z-index: 0;
    color: var(--accent-color);
    transition: transform 1s ease-in-out;
}

.contact-email:hover::after {
    transform: scaleY(1);
}

.footer-tools ul {
    /* Add styles for the tools list */
}

.footer-tools li {
    /* Add styles for the tools list items */
}
</style>
