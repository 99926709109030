<template>
    <div class="navbar">
        <div class="navbar-item nav-icon">
          <img src="../assets/images/logos/icon_nav_bar_2.png" alt="Logo">
        </div>
      <div class="navbar-end">
        <div @click="changeLanguage" class="navbar-item nav-icon">
          {{ currentSelectedLanguage.toLocaleUpperCase() }}
        </div>
        <div @click="toggleDarkMode" class="navbar-item">
          <i v-if="!this.isDarkMode" class="nav-icon nav-icon nav-icon__rotate color-mode flipped-moon fa-regular fa-moon fa-lg"></i>
          <i v-else-if="this.isDarkMode" class="nav-icon nav-icon nav-icon__rotate color-mode  fa-solid fa-sun fa-lg"></i>
        </div>
      </div>
    </div>
    <!-- Your other template content -->
  </template>
  
  <script>
  import { inject } from 'vue';
  export default {
    name: 'NavBar',
    setup() {
        const popupState = inject('popupState');
        function openPopup() {
            popupState.value = true;
        }
        return { openPopup };
    },
    data() {
      return {
        currentSelectedLanguage: sessionStorage.getItem('language') == "spanish" ? "es" : "en",
        supportedLanguages: {
          english: 'en',
          spanish: 'es'
        },
        colorMode: "light-mode",
        isDarkMode: false
      };
    },
    methods: {
      toggleDarkMode() {
        if (this.colorMode == "light-mode") {
          this.colorMode = "dark-mode";
          this.isDarkMode = true;
        }
        else {
          this.colorMode = "light-mode";
          this.isDarkMode = false;
        }

        if (this.isDarkMode) {
          document.body.classList.add("dark-mode");
        }
        else {
          document.body.classList.remove("dark-mode");
          document.body.classList.add("light-mode");
        }
      },
      changeLanguage() {
        const currentLanguage = this.currentSelectedLanguage;
        let selectedLanguage = "";

        if (currentLanguage == "en") selectedLanguage = "spanish";
        else selectedLanguage = "english"
          
        this.currentSelectedLanguage = this.supportedLanguages[selectedLanguage];
        this.$i18n.locale = this.supportedLanguages[selectedLanguage];
        sessionStorage.setItem('language', selectedLanguage);

        this.reloadPage()
      },
      
      reloadPage() {
        window.location.reload();
      }
    }
  };
  </script>

  <style scoped>
    .navbar {
      height: 100px;
      background-color: transparent;
    }
    .flipped-moon {
      color: gray;
    }

    .nav-icon .color-mode {
      transition: 0.5s;
    }

    .nav-icon__rotate {
      transform: rotate(240deg);
    }

    .nav-icon:hover {
      cursor: pointer;
      color: var(--text-color);
    }
    
    @media screen and (max-width: 768px) {
      .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .navbar-end {
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
  </style>