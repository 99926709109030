<template>
    <div v-if="isVisible.value" class="popup-overlay" @click.self="closePopup">
      <div class="popup-box">
        <button class="close-button" @click="closePopup">X</button>
        <p>This page is still under construction.</p>
      </div>
    </div>
  </template>
  
  <script>
  import { inject } from 'vue';
  
  export default {
    name: "WorkingOnItPopUp",
    setup() {
      const isVisible = inject('popupState');
  
      function closePopup() {
        isVisible.value = false;
      }
  
      return { isVisible, closePopup };
    }
  }
  </script>
  
  <style>
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-box {
    background: var(--background-color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  .close-button {
    position: absolute;
    padding: 5px 10px 5px 10px;
    top: 10px;
    right: 10px;
  }

  .close-button:hover {
    cursor: pointer;
    background-color: var(--accent-color);
  }
  </style>
  