  <template>
    <div id="ivanFAQChatBubble">
      <div id="myPopup" class="popup fade-in" :class = "{popup_hide: !isPopupVisible, chat_visible : isPopupVisible}" v-show="isPopupVisible">
        <div class="popup-header">
          <span class="bansai-chat-profile">
            <img src="../assets/images/bansaiPicture.png" alt="bansai" width="45px" height="35px" class="profile-image">
            <div class="bansai-chat-profile info">
              <p class="bansai-chat-profile info name">Bansai</p>
              <div class="bansai-chat-profile info available">
                <span class="available-dot"></span>
                <p class="bansai-chat-profile info available text"> {{ $t("CHATBOT.AVAILABLE_NOW") }} </p>
              </div>
            </div>
          </span>
          <span class="closePopUpButton" @click="closePopup">&times;</span>
        </div>
        <div class="popup-content">
          <div id="chatBox" class="chat-box">
            <div v-for="msg in messages" :key="msg.id" class="message-line">
              <div :class="['message-sender-name', msg.role === 'user' ? 'sender' : 'receiver']">
                <p class="message-sender-message-bubble-title">{{ msg.role === 'user' ? 'You' : 'Bansai' }}</p>
              </div>
              <div :class="['chat-message', msg.role, determineMessageSender(msg)]">
                <p class="message-sender-message-bubble-message">{{ msg.content }}</p>
              </div>
            </div>
          </div>
          <div class="message-container">
            <textarea id="messageTextInputBox" 
                      v-model="userInput"
                      v-on:focus="handleFocus"
                      :placeholder="$t('CHATBOT.TYPE_A_MESSAGE')"
                      @keydown.enter="sendMessage"
                      @keydown.enter.prevent>
            </textarea>
            <button @click="sendMessage">
              <div class="svg-wrapper-1">
                <div class="svg-wrapper">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path
                      fill="currentColor"
                      d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                    ></path>
                  </svg>
                </div>
              </div>
              <span> {{ $t("CHATBOT.SEND") }} </span>
            </button>
          </div>
          <div class="powered-by">
            <a href="#" style="color: rgb(153, 153, 153); text-decoration: none;" target="_blank"> Powered by OpenAi</a>
          </div>
        </div>
      </div>
  
      <div class="help-message" :class = "{help_message_hide: !showHelpMessage, help_message_visible: isPopupVisible}" v-show="showHelpMessage">Want to know more about Iván? Ask some questions!</div>
      
      <div id="popupButton" class="popup-button" @click="openPopup" v-show="showPopupButton">
        <i class="fa-regular fa-comment fa-2xl"></i>
      </div>
    </div>
  </template>
 
  <script>
  export default {
    name: "ChatBubble",
    data() {
      return {
        messages: [],
        userInput: '',
        isPopupVisible: false,
        showHelpMessage: false,
        showPopupButton: true,
      };
    },
    mounted() {
      this.initMessages();
      this.scheduleHelpMessage();
    },
    methods: {
      initMessages() {
        this.messages.push({
          id: 1, // Unique identifier for each message
          role: 'system',
          content: this.$t('CHATBOT.PROMPT_MESSAGE'),
        });
        // Add more initialization messages if needed
      },
      openPopup() {
        this.isPopupVisible = true;
        this.showPopupButton = false;
      },
      closePopup() {
        this.isPopupVisible = false;
        this.showPopupButton = true;
      },
      sendMessage() {
        if (this.userInput.trim() === '') return;
        if (this.userInput.length > 128) {
          alert('Message cannot be longer than 128 characters');
          return;
        }
  
        this.addMessageToChatBox(this.userInput, 'user');
        this.sendMessageToServer(this.userInput);
        this.userInput = '';
      },
      addMessageToChatBox(message, role) {
        this.messages.push({
          id: this.messages.length + 1, // Generate a new unique id
          role,
          content: message,
        });
      },
      scheduleHelpMessage() {
        setTimeout(() => {
          this.showHelpMessage = true;
  
          setTimeout(() => {
            this.showHelpMessage = false;
          }, 10000);
        }, 2000);
      },
      async sendMessageToServer(userMessage) {
        var myHeaders = new Headers();
        myHeaders.append("X-API-KEY", "wi@txu01k=39mm@xmrg2ngvt4ekuzp238fj437gshdul?");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "message": userMessage
        });

        try {
          const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

        const response = fetch(`https://ivan-personal-portfolio-api.vercel.app/open-ai/get-assistant-response`, requestOptions);
          let res = await response;
          let JSONResult = await res.json();

          // Process the ratelimiting error
          if (JSONResult?.status == 429 && JSONResult?.type == 'error') {
            this.addMessageToChatBox(JSONResult?.message, 'bansai');
          }
          else {
            let assistantResponse = JSONResult.assistantResponse;
            this.addMessageToChatBox(assistantResponse, 'bansai');
          }
        }
        catch (err) {
          console.log(err);
        }
      },
      determineMessageSender(msg) {
        if (msg.role === 'user') {
          return 'sent';
        }
        return 'received';
      },
      handleFocus() {
        this.showHelpMessage = false;
      },
    },
  };
  </script>
  
  <style>
    .popup {
        position: fixed;
        right: 37px;
        bottom: 37px;
        background-color: var(--chat-user-background-color);
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1000;
        border-radius: 5px;
        flex-direction: column;
        height: 500px;
        width: 400px;
    }
    
    .popup_hide {
      display: none;
    }

    .help_message_hide {
      display: none;
    }

    .popup-content {
        height: 90%;
        padding: 0px;
        text-align: center;
    }
    .popup-header{

        display: flex;
        justify-content: space-between;
    }

    .closePopUpButton {
        margin-right: 10px;
        color: var(--secondary-color);
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .closePopUpButton:hover,
    .closePopUpButton:focus {
        cursor: pointer;
    }

    .texting-area {
        width: 90%;
        margin-bottom: 10px;
    }

    .message-line {
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
    }

    .message-sender-name {
        margin-bottom: 2px;
    }

    .message-sender-name.sender {
        text-align: end;
    }

    .message-sender-name.receiver {
        text-align: start;
    }

    .message-sender-message-bubble-title {
        font-size: var(--text-font-size);
        font-family: 'Nunito Sans', sans-serif;
        margin: 0;
    }

    .message-sender-message-bubble-message {
        font-size: var(--text-font-size);
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 400;
        margin: 0;
    }

    /* Chat box */
    .chat-box {
        height: 85%;
        overflow-y: auto;
        border: 1px 1px 0px 1px solid #ddd;
        margin-bottom: 10px;
        padding: 10px;
        background-color: var(--chatbox-background-color);
    }

    .chat-message {
        font-size: 14px;
        margin: 5px 0;
        padding: 5px;
        border-radius: 10px;
    }

    .sent {
        display: inline-block;
        color: var(--dark-text-color);
        background-color: var(--primary-color);
        text-align: left;
        padding: 5px 10px;
        border-radius: 18px 0 18px 18px;
        margin: 5px 0;
        word-wrap: break-word;
        margin-left: auto; /* Align to the right */
        max-width: 80%; /* Maximum width of the bubble */
    }

    .received {
        display: inline-block;
        color: #ffff;
        background-color: var(--secondary-color);
        text-align: left;
        padding: 5px 10px;
        border-radius: 0px 18px 18px 18px;
        margin: 0 0 5px 0;
        word-wrap: break-word;
        max-width: 75%;
        margin-right: auto; /* Align to the right */
    }

    .popup-button {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: fixed;
        right: 20px;
        bottom: 20px;
        background-color: var(--secondary-color);
        color: var(--dark-text-color);
        border-radius: 50%;
        width: 60px;
        height: 60px;
        text-align: center;
        line-height: 50px;
        cursor: pointer;
        z-index: 1001; /* Above the popup */
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        animation: moveUpAndDown 5s ease-in-out infinite;
    }

    .popup-button:hover {
        transition: 1s;
        background-color: var(--accent-color);
        box-shadow: 0 5px 25px 3px color-mix(in srgb, var(--accent-color), transparent 50%);

    }

    .message-container {
        display: flex;
        align-items: center;
        height: 10%;
        width: 100%;
        box-shadow: 0px -5px 4px rgba(0,0,0,0.1);

    }

    .profile-image {
        border-radius: 45px;
    }

    .bansai-chat-profile {
        display: flex;
        margin-left: 5px;
        margin-top: 2px;
    }

    .bansai-chat-profile.info {
        display: flex;
        flex-direction: column;
        font-family: 'Nunito Sans', sans-serif;
        margin-left: 6px;
        margin-top: 0px;
    }

    .bansai-chat-profile.info.name {
        margin: 0;
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 700;
        margin-bottom: -5px;
    }

    .bansai-chat-profile.info.available {
        display: flex;
        flex-direction: row;
        margin: 0;
        font-size: 10px;
    }

    .bansai-chat-profile.info.available.text {
        margin: 0;
        font-weight: 300;
    }

    .available-dot {
        align-self: center;
        margin-right: 5px;
        height: 6px;
        width: 6px;
        background-color: rgb(17, 203, 67);
        border-radius: 50%;
        display: inline-block;
    }

    #messageTextInputBox {
        background-color: var(--chat-user-background-color);
        color: var(--text-color);
        border: 0;
        margin-bottom: 0;
        flex-grow: 8;
        padding: 10px;
        resize: none;
        white-space: break-word;
        overflow: hidden;
        resize: none;
    }

    #messageTextInputBox:focus {
        outline: none;
    }

    button {
      font-family: inherit;
      font-size: 20px;
      background: var(--secondary-color);
      color: var(--dark-text-color);
      padding: 0.7em 1em;
      padding-left: 0.9em;
      display: flex;
      align-items: center;
      border: none;
      border-radius: 4px;
      overflow: hidden;
      transition: all 0.2s;
      cursor: pointer;
    }

    button span {
      display: block;
      margin-left: 0.3em;
      transition: all 0.3s ease-in-out;
    }

    button svg {
      display: block;
      transform-origin: center center;
      transition: transform 0.3s ease-in-out;
    }

    button:hover .svg-wrapper {
      animation: fly-1 0.6s ease-in-out infinite alternate;
    }

    button:hover svg {
      transform: translateX(1.2em) rotate(45deg) scale(1.1);
    }

    button:hover span {
      transform: translateX(5em);
    }

    button:active {
      transform: scale(0.95);
    }

    @keyframes fly-1 {
      from {
        transform: translateY(0.1em);
      }

      to {
        transform: translateY(-0.1em);
      }
    }


    .help-message {
        position: fixed;
        bottom: 28px; /* Adjusted to appear above the popupButton */
        right: 50px; /* Start near the popupButton */
        background-color: var(--secondary-color);
        color: var(--text-color);
        padding: 10px 20px;
        border-radius: 30px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        white-space: nowrap;
        display: none; /* Initially hidden */
    }

    .powered-by {
      height: 5%;
        text-align: center;
        padding: 5px;
        font-size: 12px;
        background-color: var(--chat-user-background-color)
    }

    .powered-by a {
        color: #007bff; /* Color for the link, adjust as needed */
        text-decoration: none; /* Removes underline from the link */
    }

    .powered-by a:hover {
        text-decoration: underline; /* Adds underline on hover for better UX */
    }

    @keyframes slideOut {
        from {
            right: 50px;
            opacity: 0;
        }
        to {
            right: 100px; /* Adjust as needed */
            opacity: 1;
        }
    }

    @keyframes slideIn {
        from {
            right: 100px;
            opacity: 1;
        }
        to {
            right: 50px; /* Slide back to the starting position */
            opacity: 0;
        }
    }

    .chat_visible {
        visibility: visible;
        opacity: 1;
        transform: translateY(20px);
        animation: fadeInUp 1s ease forwards;
        transition: opacity 2s linear;
    }

    @media screen and (max-width: 768px) {
        .popup {
            width: 100%;
            height: 100%;
            border-radius: 0px;
            bottom: 0px;
            right: 0px;
        }
        .popup-content {
            height: 90%;
        }
        .popup-header {
            justify-content: space-between;
        }
        .popup-button {
            right: 5px;
            bottom: 5px;
        }
        .help-message {
            right: 0px;
        }
    }

    @keyframes fadeInUp {
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes moveUpAndDown {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-5px);
        }
        100% {
            transform: translateY(0);
        }
    }
  
  </style>
  