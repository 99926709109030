<template>
    <div ref="mouseCursor" class="mouse-cursor"></div>
  </template>
  
  <script>
  export default {
    name: 'MouseTracker',
    data() {
      return {
        lastX: 0,
        lastY: 0,
        width: 0, // Width of the rectangle
        height: 8, // Height of the rectangle
        maxWidth: 100, // Maximum width or height
        maxHeight: 25,
        isMousePointing: false,
        isMouseDown: false,
      };
    },
    mounted() {
        this.mouseCursor = this.$refs.mouseCursor;
        this.mouseGlow = this.$refs.mouseGlow;
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mousedown', this.onMouseDown);
        document.addEventListener('mouseup', this.onMouseUp);
        document.addEventListener('mousemove', this.adjustCursorStyle);
        document.addEventListener('mouseleave', this.hideCursor);
        document.addEventListener('mouseenter', this.showCursor);
    },
    beforeUnmount() {
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mousemove', this.adjustCursorStyle);
        document.removeEventListener('mousedown', this.onMouseDown);
        document.removeEventListener('mouseup', this.onMouseUp);
        document.removeEventListener('mouseleave', this.hideCursor);
        document.removeEventListener('mouseenter', this.showCursor);
    },
    methods: {
        handleMouseMove(event) {            
            const deltaX = event.pageX - this.lastX;
            const deltaY = event.pageY - this.lastY;

            // Timer for 1 second if the mouse is not moving hide the cursor
            
            // Calculate the angle of movement
            const angle = Math.atan2(deltaY, deltaX) * 180 / Math.PI;

            // Calculate the length of the movement vector
            const movementLength = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
            
            // Calculate the new width based on the movement length
            let newWidth = Math.min(this.width + movementLength, this.maxWidth) * 1.5;
            let newHeight = Math.min(this.height + movementLength, this.maxHeight) / 3;

            // Apply new dimensions
            this.mouseCursor.style.width = `${newWidth}px`;
            this.mouseCursor.style.height = `${newHeight}px`;

            // Update the cursor's position and rotation
            let cursorOffset = (this.isMouseDown || this.isMousePointing) ? 12.5 : 0;
            this.mouseCursor.style.left = `${(event.pageX - newWidth / 2) - cursorOffset}px`;
            this.mouseCursor.style.top = `${event.pageY - this.height / 2 - cursorOffset}px`;
            this.mouseCursor.style.transform = `rotate(${angle}deg)`;

            // Update last positions
            this.lastX = event.pageX;
            this.lastY = event.pageY;
        },
        adjustCursorStyle(event) {
          // Adjust coordinates for scrolling
            const elemBelow = document.elementFromPoint(event.clientX, event.clientY);
            if (elemBelow && window.getComputedStyle(elemBelow).cursor === 'pointer') {
              if(!this.isMousePointing) this.isMousePointing = true;
              this.mouseCursor.classList.add('pointer-cursor');
            }
            else {
              if(this.isMousePointing) this.isMousePointing = false;
              this.mouseCursor.classList.remove('pointer-cursor');
            }
        },
        onMouseDown(event) {
          this.isMouseDown = true;
          // Center the mouse in the cube
          this.mouseCursor.style.left = `${(event.pageX - this.width / 2) - 12.5}px`;
          this.mouseCursor.style.top = `${event.pageY - this.height / 2 - 12.5}px`;
          this.mouseCursor.classList.add('mouse-down');
        },
        onMouseUp(event) {
          this.isMouseDown = false;
          // Decenter the mouse from the cube
          this.mouseCursor.style.left = `${(event.pageX - this.width / 2)}px`;
          this.mouseCursor.style.top = `${event.pageY - this.height / 2}px`;
          this.mouseCursor.classList.remove('mouse-down');
        },
        hideCursor() {
            this.mouseCursor.style.display = 'none';
        },
        showCursor() {
            this.mouseCursor.style.display = 'block';
        },
    },
  };
  </script>
  
  <style>
  .mouse-cursor {
    position: absolute;
    pointer-events: none;
    background-color: var(--accent-color);
    opacity: 0.5;
    transform-origin: center;
    box-shadow: 0 0 25px 1px var(--accent-color); /* Glowing effect */
  }

  .pointer-cursor {
    transition: 0.15s;
    width: 25px !important;
    transform: rotate(0deg) !important;
    height: 25px !important;
    opacity: 0.5 !important;
    z-index: 9999 !important;
  }

  .mouse-down {
    transition: 0.15s;
    width: 35px !important;
    background-color: transparent !important;
    border: 2px solid var(--accent-color) !important;
    transform: rotate(0deg) !important;
    height: 35px !important;
    opacity: 0.5 !important;
    z-index: 9999 !important;
  }

  .mouse-glow {
    position: absolute;
    pointer-events: none;
    width: 1px; /* Larger than the cursor */
    height: 1px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.0); /* Semi-transparent white */
    box-shadow: 0 0 20px 5px var(--accent-color); /* Glowing effect */
    transform: translate(-50%, -50%); /* Center the glow on the cursor */
    transition: 0.3 /* Smooth transition for the trailing effect */
}
  </style>