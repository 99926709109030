<template>
    <section class="professional-experience-section">
        <h2 class="section-title">
            <div>
                {{ $t('PROFESSIONAL_EXPERIENCE.PROFESSIONAL') }}
                <div class="section-title__underline section-title__underline--professional"></div>
            </div> 
            <div>
                {{ $t('PROFESSIONAL_EXPERIENCE.EXPERIENCE') }}
                <div class="section-title__underline section-title__underline--experience"></div>
            </div>
        </h2>
        <div class = "professional-experience-samples">
            <ProfessionalExperienceBox
            :jobTitle = this.datadog.jobTitle
            :dates = this.datadog.dates
            :location = this.datadog.location
            :url = this.datadog.url
            :technologiesUsed = "murexTechnologies"
            :imageName = "murexImageURL"
            >
            </ProfessionalExperienceBox>

            <ProfessionalExperienceBox
            :jobTitle = this.murex.jobTitle
            :jobDescription = this.murex.jobDescription
            :dates = this.murex.dates
            :location = this.murex.location
            :url = this.murex.url
            :technologiesUsed = "murexTechnologies"
            :imageName = "murexImageURL"
            >
            </ProfessionalExperienceBox>

            <ProfessionalExperienceBox
            :jobTitle = this.nimblr.jobTitle
            :jobDescription = this.nimblr.jobDescription
            :dates = this.nimblr.dates
            :location = this.nimblr.location
            :url = this.nimblr.url
            :technologiesUsed = "nimblrTechnologies"
            :imageName = "nimblrImageURL"
            >
            </ProfessionalExperienceBox>
        </div>

    </section>
  </template>
  
  <script>
  import ProfessionalExperienceBox from './ProfessionalExperienceBox.vue'

  export default {
    name: 'ProfessionalExperience',
    components: {
        ProfessionalExperienceBox
    },
    data () {
      return {
        datadog: {
            jobTitle: `${this.$t('PROFESSIONAL_EXPERIENCE.DATADOG.POSITION')} @ DataDog`,
            dates: this.$t('PROFESSIONAL_EXPERIENCE.DATADOG.DATES'),
            location: `${this.$t('PROFESSIONAL_EXPERIENCE.DATADOG.LOCATION')} (${this.$t('PROFESSIONAL_EXPERIENCE.DATADOG.WORK_MANNER')})`,
            url: "https://www.murex.com/"
        },
        dataDogTechnologies: ["Java Script", "Python", "Node.js", "MX.3", "MXTest", "Linux", "Bash"],
        dataDogImageURL: "murex_logo.png",
        murex: {
            jobTitle: `${this.$t('PROFESSIONAL_EXPERIENCE.MUREX.POSITION')} @ Murex`,
            dates: this.$t('PROFESSIONAL_EXPERIENCE.MUREX.DATES'),
            jobDescription: this.$t('PROFESSIONAL_EXPERIENCE.MUREX.JOB_DESCRIPTION'),
            location: `${this.$t('PROFESSIONAL_EXPERIENCE.MUREX.LOCATION')} (${this.$t('PROFESSIONAL_EXPERIENCE.MUREX.WORK_MANNER')})`,
            url: "https://www.murex.com/"
        },
        murexTechnologies: ["Java Script", "Python", "Node.js", "MX.3", "MXTest", "Linux", "Bash"],
        murexImageURL: "murex_logo.png",
        nimblr: {
            jobTitle: `${this.$t('PROFESSIONAL_EXPERIENCE.NIMBLR.POSITION')} @ Nimblr.ai`,
            dates: this.$t('PROFESSIONAL_EXPERIENCE.NIMBLR.DATES'),
            jobDescription: this.$t('PROFESSIONAL_EXPERIENCE.NIMBLR.JOB_DESCRIPTION'),
            location: `${this.$t('PROFESSIONAL_EXPERIENCE.NIMBLR.LOCATION')} (${this.$t('PROFESSIONAL_EXPERIENCE.NIMBLR.WORK_MANNER')})`,
            url: "https://nimblr.ai/"
        },
        nimblrTechnologies: ["Java Script", "Vue.js", "Node.js", "AWS", "Docker", "GPT", "Google API's"],
        nimblrImageURL: "Holly_VER_Blue_EN 1.png"
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .professional-experience-samples {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .professional-experience-section {
        padding-bottom: 100px;
    }
    .section-title__underline--professional {
        background-image: linear-gradient(to right, var(--primary-color));
        width: 50%;
        margin-top: -35px;
        height: 27px;
        animation: moveGradient 15s linear infinite;
    }

    .section-title__underline--experience {
        background-image: linear-gradient(to right, var(--accent-color), var(--secondary-color));
        width: 40%;
        margin-top: -35px;
        height: 27px;
        animation: moveGradient 20s linear infinite;
    }

    @media screen and (max-width: 768px) {
        .professional-experience-section {
            padding-bottom: 30px;
        }
        
        .section-title__underline {
            height: 15px;
            margin-top: -22px;
        }
        .section-title__underline--professional {
            width: 80%;
        }

        .section-title__underline--experience {
            width: 70%
        }
    }

  </style>
  