import { createApp, reactive } from 'vue'
import { createI18n } from 'vue-i18n'
import * as es from './language/es.json'
import * as en from './language/en.json'
import "./styles/main.css"
import "./styles/bulma.css"
import App from './App.vue'
import PopupComponent from './components/WorkingOnItPopUp.vue';

const popupState = reactive({ isVisible: false });
const storedLanguage = sessionStorage.getItem('language') == "spanish" ? 'es' : 'en';


const i18n = createI18n({
    locale: storedLanguage,
    fallbackLocale: storedLanguage, // set fallback locale
    messages: {
        en,
        es
    }
})

const app = createApp(App)
app.component('popup-component', PopupComponent);
app.provide('popupState', popupState);

app.use(i18n)

app.mount('#app')
