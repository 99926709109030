<template>
    <section class = "title-and-bio-section">
        <div class="title-and-bio columns">
            <div class="column is-half intro-description ">
                <h1 class="section-title">
                    Iván Díaz
                    <div class="section-title__underline"></div>
                </h1>
                <h3 class="job-title">
                    {{ $t("BIOGRAPHY.JOB_TITLE") }}
                </h3>
                <p class="personal-description-title">
                    {{ $t("BIOGRAPHY.SHORT_DESCRIPTION") }}
                </p>
                <div v-if='isMobile' class="social-networks-icons social-network-icons__mobile column is-half">
                    <a href="https://www.linkedin.com/in/ivan-diaz-lara-550488213/" target="_blank" rel="noopener noreferrer">
                        <i class="fab fa-linkedin fa-2xl"></i>
                    </a>
                    <a href="https://github.com/IvanDLar" target="_blank" rel="noopener noreferrer">
                        <i class="fab fa-github fa-2xl"></i>
                    </a>
                </div>
                <h4 v-if='isMobile' class='about'>
                    {{ $t("BIOGRAPHY.ABOUT") }}
                </h4>
            </div>
            <div class="column is-half small-bio-column">
                <h3 class = "small-bio">
                    {{ $t("BIOGRAPHY.LONG_DESCRIPTION.PARAGRAPH_1") }}
                    <br><br>
                    {{ $t("BIOGRAPHY.LONG_DESCRIPTION.PARAGRAPH_2") }}
                    <br><br>
                    {{ $t("BIOGRAPHY.LONG_DESCRIPTION.PARAGRAPH_3") }}
                </h3>
            </div>
        </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'TitleAndBiography',
    computed: {
        isMobile() {
            return window.innerWidth < 768;
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .title-and-bio {
        margin-bottom: 100px;
    }
    .section-title {
        margin-bottom: 10px;
    }
    .section-title__underline{
        margin-left: 0;
        margin-top: -35px;
        width: 80%;
        animation: moveGradient 7s linear infinite;
    }
    .job-title {
        font-size: var(--subtitle-font-size);
        font-weight: bold;
    }
    .personal-description-title {
        font-size: var(--text-font-size);
        max-width: 60%;
    }
    .intro-description {
        text-align: left;
    }

    .small-bio {
        text-align: left;
        font-size: var(--text-font-size);
    }

    .about {
        font-size: var(--mobile-subtitle-font-size);
        font-weight: bold;
        margin-top: 20px;
    }

    @media screen and (max-width: 768px) {
        .title-and-bio {
            margin-bottom: 50px;
        }
        .section-title {
            font-size: var(--mobile-title-font-size);
        }
        .section-title__underline {
            margin-top: -25px;
            height: 15px;
            width: 50%;
        }
        .job-title {
            font-size: var(--mobile-subtitle-font-size);
        }
        .personal-description-title {
            font-size: var(--mobile-text-font-size);
            max-width: 100%;
        }
        .intro-description {
            padding-bottom: 0;
        }
        .small-bio {
            font-size: var(--text-font-size);
        }
        .small-bio-column {
        padding-top:2px;
    }
    }
  </style>
  