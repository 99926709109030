<template>
    <section class="my-expertise-section">
        <h2 class="section-title">
            {{ $t("EXPERTISE.MY_EXPERTISE") }}
            <div class="section-title__underline"></div>
        </h2>
        <div class="columns is-3">
            <div class="column is-one-third">
                <div class="expertise-box">
                    <div class="columns title-and-icon is-mobile">
                    <div class="icon-column column is-2">
                        <i class="icon fa-solid fa-desktop fa-2xl"></i>
                    </div>
                    <div class="column is-10">
                        <h3 class="box__title">
                            {{ $t("EXPERTISE.SOFTWARE") }} <br> &nbsp; &nbsp;  &nbsp; {{ $t("EXPERTISE.ENGINEERING") }}
                        </h3>
                        <div class="section-subtitle__underline section-subtitle__underline--software"></div>
                    </div>
                    </div>
                    <div class="columns description-box is-mobile">
                        <div class="column is-2 tall-line-breaker__column">
                            <div class="tall-line-breaker"></div>
                        </div>
                        <div class="column">
                            <p class = "content">
                                {{ $t("EXPERTISE.SOFTWARE_ENGINEERING_DESCRIPTION") }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column is-one-third">
                <div class="expertise-box">
                    <div class="columns title-and-icon is-mobile">
                    <div class="icon-column column is-2">
                        <i class="icon fa-solid fa-comment fa-2xl"></i>
                    </div>
                    <div class="column">
                        <h3 class="box__title">
                            {{ $t("EXPERTISE.PROMPT") }} <br> &nbsp; &nbsp;  &nbsp; {{ $t("EXPERTISE.ENGINEERING") }}
                        </h3>
                        <div class="section-subtitle__underline section-subtitle__underline--prompt-eng"></div>
                    </div>
                    </div>
                    <div class="columns description-box is-mobile">
                        <div class="column is-2 tall-line-breaker__column">
                            <div class="tall-line-breaker"></div>
                        </div>
                        <div class="column">
                            <p class = "content">
                                {{ $t("EXPERTISE.PROMPT_ENGINEERING_DESCRIPTION") }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column is-one-third ">
                <div class="expertise-box">
                    <div class="columns title-and-icon is-mobile">
                    <div class="icon-column column is-2">
                        <i class="icon fa-solid fa-gears fa-2xl"></i>
                    </div>
                    <div class="column">
                        <h3 class="box__title">
                            {{ $t("EXPERTISE.MACHINE") }} <br> &nbsp; &nbsp;  &nbsp; {{ $t("EXPERTISE.LEARNING") }}
                        </h3>
                        <div class="section-subtitle__underline section-subtitle__underline--machine-learning"></div>
                    </div>
                    </div>
                    <div class="columns description-box is-mobile">
                        <div class="column is-2 tall-line-breaker__column">
                            <div class="tall-line-breaker"></div>
                        </div>
                        <div class="column">
                            <p class = "content">
                                {{ $t("EXPERTISE.MACHINE_LEARNING_DESCRIPTION") }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'MyExpertise'
  }
  </script>
  
  <style scoped>
    .my-expertise-section {
        margin-bottom: 100px;
        transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
    }

    .section-title__underline {
        background-image: linear-gradient(to right,  var(--accent-color), var(--secondary-color));
        width: 60%;
        margin-top: -35px;
        height: 27px;
        animation: moveGradient 15s linear infinite;
    }

    .expertise-box {
        position: sticky;
        background-color: color-mix(in srgb, var(--primary-color), transparent 95%);
        height: 300px;
        border-radius: 10px;
        box-shadow: 0px 8px 8px -10px rgba(0,0,0,0.5);
        animation: slideUp 0.6s ease-out;
        opacity: 0;
        animation-fill-mode: forwards;
        padding-right: 20px;
    }

    .columns.is-3 .column:nth-child(1) .expertise-box {
        animation-delay: 0.2s;
    }

    .columns.is-3 .column:nth-child(2) .expertise-box {
        animation-delay: 0.4s;
    }

    .columns.is-3 .column:nth-child(3) .expertise-box {
        animation-delay: 0.6s;
    }

    .icon {
        color: var(--secondary-color);
    }
    
    .icon-column {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 25px;
    }
    .title-and-icon {
        height: 30%;
    }

    .tall-line-breaker {
        height: 0%;
        opacity: 0.5;
        background-color: var(--secondary-color);
        width: 4px;
        animation: growHeight 0.8s ease-out;
        animation-fill-mode: forwards; /* Keep the element in the final state */
        animation-delay: 0.8s; /* Delay to start after slideUp animation */
    }

    .columns.is-3 .column:nth-child(1) .tall-line-breaker {
        animation-delay: 0.4s; /* 0.2s (slideUp delay) + 0.8s (slideUp duration) */
    }

    .columns.is-3 .column:nth-child(2) .tall-line-breaker {
        animation-delay: 0.6s; /* 0.4s (slideUp delay) + 0.8s (slideUp duration) */
    }

    .columns.is-3 .column:nth-child(3) .tall-line-breaker {
        animation-delay: 0.8s; /* 0.6s (slideUp delay) + 0.8s (slideUp duration) */
    }

    .tall-line-breaker__column {
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        flex-direction: row-reverse;
    }

    .content {
        font-size: var(--text-font-size);
        text-align: left;
    }

    .section-subtitle__underline--software {
        margin-top: -52px;
        width: 60%;
    }
    .section-subtitle__underline--machine-learning {
        margin-top: -52px;
        width: 63%;
    }

    .section-subtitle__underline--prompt-eng {
        margin-top: -52px;
        width: 50%;
    }
    @media screen and (max-width: 768px) {

        .section-title__underline {
            margin-top: -20px;
            height: 20px;
            width: 50%;
        }
        .expertise-box {
            height: 300px;
        }
        .section-subtitle__underline--software {
            margin-top: -40px;
            width: 50%;
        }
        .section-subtitle__underline--machine-learning {
            margin-top: -40px;
            width: 48%;
        }
        .section-subtitle__underline--prompt-eng {
            margin-top: -40px;
            width: 38%;
        }

        .columns.title-and-icon {
            display: flex;
            justify-content: left;
        }

        .icon-column {
            justify-content: left;
        }

        .column > .box__title { 
            justify-content: left;
        }
    }
    
    @keyframes slideUp {
        from {
            transform: translateY(40px); /* Start from 20px below */
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }
    
    @keyframes growHeight {
        from {
            height: 0;
        }
        to {
            height: 100%;
        }
    }
  </style>
  