<template>
  <MouseTracker v-if = '!isMobile'/>
  <div :class="{ blur: isPopupVisible }">
    <NavBar/>
    <TitleAndBiography/>
    <MyExpertise/>
    <MyProjects/>
    <ProfessionalExperience/>
    <ChatBubble/>
    <ContactFooter/>
  </div>
  <WorkingOnItPopUp/>
  <SpeedInsights/>
</template>

<script>
import { computed, inject } from 'vue';

import TitleAndBiography from './components/TitleAndBiography.vue'
import NavBar from './components/NavBar.vue'
import MyExpertise from './components/MyExpertise.vue'
import MyProjects from './components/MyProjects.vue'
import ProfessionalExperience from './components/ProfessionalExperience.vue'
import MouseTracker from './components/MouseTracker.vue'
import ChatBubble from './components/ChatBubble.vue'
import WorkingOnItPopUp from './components/WorkingOnItPopUp.vue'
import ContactFooter from './components/ContactFooter.vue'
import { SpeedInsights } from "@vercel/speed-insights/vue"

export default {
  name: 'App',
  components: {
    TitleAndBiography,
    NavBar,
    MyExpertise,
    MyProjects,
    ProfessionalExperience,
    MouseTracker,
    ChatBubble,
    WorkingOnItPopUp,
    ContactFooter,
    SpeedInsights
  },
  setup() {
    const popupState = inject('popupState');
    const isPopupVisible = computed(() => popupState.value);
    return { isPopupVisible };
  },
  computed: {
        // Make a function that returns true when the display is smaller than 768px.
        isMobile() {
            return window.innerWidth < 768;
        }
  }
}
</script>
<style scoped>
  .blur {
    filter: blur(5px);
  }
</style>

