<template>
    <section class = "my-project-section">
        <h2 class="section-title section-title__projects">
            {{ $t("PROJECTS.MY_PROJECTS") }}
            <div class="section-title__underline"></div>
        </h2>
        <div class="columns">
            <!-- <div class="column is-one-half">
                <div class="project-box" @click="openPopup">
                    <div class="columns" style="height: 15%;">
                        <div class="column is-4"></div>
                        <div class="column">
                            <h3 class="box__title box__title_projects">
                                {{ $t("PROJECTS.SECOND_CHANCE.TITLE") }}
                            </h3>
                            <div class="section-subtitle__underline section-subtitle__underline--second-chance"></div>
                        </div>
                    </div>
                    <div class="columns" style="height: 55%;">
                        <div class="column is-4 image-column">
                            <img class="project-image" src="../assets/images/second_chance_img.png" alt="Second Chance">
                        </div>
                        <div class="column content-column">
                            <p class = "box__content">
                                {{ $t("PROJECTS.SECOND_CHANCE.DESCRIPTION") }}
                            </p>
                        </div>
                    </div>
                    <div class="columns" style="height: 15%;">
                        <div class="column round-tag-column">
                            <div class="round-tag"> React </div>
                            <div class="round-tag"> Tailwind </div>
                            <div class="round-tag"> Node.js </div>
                            <div class="round-tag"> AWS </div>
                            <div class="round-tag"> MongoDB </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="column is-one-half">
                <div class="project-box" @click="redirectToIvonneUrl">
                    <div class="columns" style="height: 15%;">
                        <div class="column is-4"></div>
                        <div class="column">
                            <h3 class="box__title box__title_projects">
                                {{ $t("PROJECTS.PSIC_IVONNE_LARA.TITLE") }}
                            </h3>
                            <div class="section-subtitle__underline section-subtitle__underline--psic-ivonne"></div>
                        </div>
                    </div>
                    <div class="columns" style="height: 55%;">
                        <div class="column is-4 image-column">
                            <img class="project-image" src="../assets/images/psicIvonneLara.png" alt="Second Chance">
                        </div>
                        <div class="column content-column">
                            <p class = "box__content">
                                {{ $t("PROJECTS.PSIC_IVONNE_LARA.DESCRIPTION") }}
                            </p>
                        </div>
                    </div>
                    <div class="columns" style="height: 20%;">
                        <div class="column round-tag-column">
                            <div class="round-tag"> React </div>
                            <div class="round-tag"> Bootstrap </div>
                            <div class="round-tag"> Next.js </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns projects-columns">
            <div class="column is-one-half">
                <div class="project-box" @click="openPopup">
                    <div class="columns" style="height: 15%;">
                        <div class="column is-4"></div>
                        <div class="column">
                            <h3 class="box__title box__title_projects">
                                {{ $t("PROJECTS.ANOM_SEARCH_HEP.TITLE") }}
                            </h3>
                            <div class="section-subtitle__underline section-subtitle__underline--anom-search"></div>
                        </div>
                    </div>
                    <div class="columns" style="height: 55%;">
                        <div class="column is-4 image-column">
                            <img class="project-image" src="../assets/images/hephiggs2.png" alt="Second Chance">
                        </div>
                        <div class="column content-column">
                            <p class = "box__content">
                                {{ $t("PROJECTS.ANOM_SEARCH_HEP.DESCRIPTION") }}

                            </p>
                        </div>
                    </div>
                    <div class="columns" style="height: 20%;">
                        <div class="column round-tag-column">
                            <div class="round-tag"> Python </div>
                            <div class="round-tag"> Tensorflow 2.0 </div>
                            <div class="round-tag"> Latex </div>
                            <div class="round-tag"> Pandas </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column is-one-half">
                <div class="project-box more-to-come__box" @click='goToContactSection'>
                    <div class="columns" style="height: 33%;"></div>
                        <div class="columns" style="height: 33%;">
                            <div class="column more-to-come__column">
                                {{ $t("PROJECTS.MORE_TO_COME") }}
                            </div>
                        </div>
                    <div class="columns" style="height: 33%;"></div>
                </div>
            </div>
        </div>
    </section>
  </template>
  
  <script>
  import { inject } from 'vue';
  export default {
    name: 'MyProjects',
    data () {
        return {
            windowWidth: window.innerWidth
        }
    },
    setup() {
        const popupState = inject('popupState');
        function openPopup() {
            popupState.value = true;
        }
        return { openPopup };
    },
    created() {
        window.addEventListener('resize', this.handleResize);
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        redirectToIvonneUrl() {
            window.open("http://psic-ivonne.com/", "_blank");
        },
        goToContactSection() {
            const element = document.getElementById('contact-section');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        },
        handleResize() {
            this.isMobile = window.innerWidth
        }
    },
    computed: {
        isMobile() {
            return window.innerWidth < 768;
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .my-project-section {
        margin-bottom: 100px;
    }

    .section-title__expertise {
        margin-bottom: 50px;
    }
    .section-title__underline {
        background-image: linear-gradient(to right,  var(--accent-color), var(--secondary-color));
        width: 30%;
        height: 27px;
        margin-top: -35px;
        animation: moveGradient 25s linear infinite;
    }
    .project-box {
        position: relative;
        background-color: color-mix(in srgb, var(--secondary-color), transparent 95%);
        height: 300px;
        border-radius: 10px;
        box-shadow: 0px 5px 5px -10px rgba(0,0,0,0.05);
        transition: transform 0.3s ease;
        transform-origin: top;
        transition: 0.5s;
    }

    .project-box:hover {
        cursor: pointer;
        transform: scale(1.05); /* Scale up by 5% */
        background-color: color-mix(in srgb, var(--secondary-color), transparent 90%);
        box-shadow: 0 5px 25px 3px color-mix(in srgb, var(--accent-color), transparent 80%);
        transition: 0.5s;
    }

    .project-image{
        width: 80%;
        border-radius: 3px;
        border: 6px solid color-mix(in srgb, var(--accent-color), transparent 50%);
    }
    
    .projects-columns {
        margin-top: 10px;
    }
    .image-column {
        display: flex;
        justify-content: right;
        align-items: center;
    }

    .section-subtitle__underline {
        display: flex;
        margin: -13px 0 0 0;
        width: 43%;
        z-index: 1;
    }

    .project-box:hover .section-subtitle__underline--anom-search {
        transition: 0.5s ease-in-out;
    }

    .section-subtitle__underline--second-chance {
        width: 58%;
    }

    .section-subtitle__underline--psic-ivonne {
        width: 65%;
    }

    .section-subtitle__underline--anom-search {
        width: 85%;
    }

    .content-column {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .more-to-come__column{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--subtitle-font-size);
        font-weight: 600;
    }

    @media screen and (max-width: 768px) {
        .box__content {
            text-align: center;
        }
        .project-box {
            height: 500px;
        }
        .project-image{
            width: 60%;
        }
        .section-title__underline {
            width: 40%;
            height: 15px;
            margin-top: -22px;
        }
        .section-subtitle__underline {
            margin-left: auto;
            margin-right: auto;
        }
        
        .section-subtitle__underline--second-chance {
            width: 50%;
        }
        .section-subtitle__underline--psic-ivonne {
            width: 60%;
        }
        .section-subtitle__underline--anom-search {
            width: 74%;
        }
        .round-tag-column {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .round-tag {
            margin: 0 5px;
        }
        .more-to-come__column{
            font-size: 1.5rem;
        }
        .box__title_projects {
            font-weight: 600;
            text-align: center;
        }
        .image-column {
            justify-content: center;
        }
    }

  </style>
  