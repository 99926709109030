<template>
    <div @click="this.toggleBox(); this.redirectToUrlTitle()" class="professional-experience-title-card">
        <h4 class="professional-experience-title">
            {{ jobTitle }}
        </h4>
        <p v-if = "!isMobile" class="professional-experience-title">
            {{ dates }}
        </p>
        <!-- Change the minux and the plus sign when opening the box-->
        <i v-if='this.jobDescription && !this.isBoxOpen' class="fa-solid fa-plus more-information-button" :class="{'fa-xs': isMobile}"></i>
        <i v-else-if='this.isBoxOpen' class="fa-solid fa-minus more-information-button" :class="{'fa-xs': isMobile}"></i>
    </div>
    
    <div  v-if="this.isBoxOpen" @click="redirectToUrlOpenedBox" ref = "descriptionBox" class = "professional-experience-description-box">
        <div class="columns">
            <div class="column is-3">
                <img :src = "require(`@/assets/images/${imageName}`)" alt="Enterprise Image" class="professional-experience-description-image">
            </div>
            <div class="column professional-experience-description-text-area">
                <div class="professional-experience-description-text-location">
                    <i class="fa-solid fa-location-dot"></i> {{ location }}
                </div>
                <h5 class="professional-experience-description-text-title professional-experience-description-text-title__job-responsibilities">
                    {{ $t(("PROFESSIONAL_EXPERIENCE.JOB_RESPONSIBILITIES")) }}:
                </h5>
                <p class="professional-experience-description-text">
                    {{ jobDescription }}
                </p>
                <h5 class="professional-experience-description-text-title">
                    {{ $t(("PROFESSIONAL_EXPERIENCE.TECHNOLOGIES_USED")) }}:
                </h5>
                <div class="columns">
                    <ul class="round-tag-column round-tag-column__professional-experience">
                        <li class="round-tag tag__professional-experience" v-for="(technology, index) in technologiesUsed" :key="index">
                            {{ technology }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ProfessionalExperienceBox',
    props: {
        jobTitle: String,
        location: String,
        jobDescription: String,
        dates: String,
        imageName: String,
        url: String,
        technologiesUsed: {
            type: Array,
            default: () => []
        },
    },
    data() {
      return {
        isBoxOpen: false
      };
    },
    methods: {
        toggleBox() {
            if (!this.jobDescription) return;
            this.isBoxOpen = !this.isBoxOpen;
            this.$nextTick(() => {
                if (this.$refs.descriptionBox) {
                    this.$refs.descriptionBox.scrollIntoView({ behavior: 'smooth' });
                }
            });
        },
        redirectToUrlTitle() {
            if (this.jobTitle == "Software Testing Engineer Intern @ Murex") window.open(this.url, '_blank')
        },
        redirectToUrlOpenedBox() {
            window.open(this.url, '_blank')
        }
    },
    computed: {
        isMobile() {
            return window.innerWidth < 768;
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .professional-experience-title-card {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        justify-content:space-between;
        height: 50px;
        width: 80%;
        background-color: color-mix(in srgb, var(--accent-color), transparent 30%);
        border-radius: 10px;
        box-shadow: 0px 5px 5px -10px rgba(0,0,0,0.05);
        transition: 1s;
        padding-left: 20px;
        padding-right: 20px;
    }
    .professional-experience-title-card:hover {
        cursor: pointer;
        background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
        box-shadow: 0 5px 25px 3px color-mix(in srgb, var(--accent-color), transparent 50%);
        transition: 1s;
    }

    .professional-experience-title {
        font-size: 20px;
        font-weight: 600;
        color: var(--dark-text-color);
    }
    
    .professional-experience-description-box {
        width: 80%;
        margin: 10px 0 20px 0;
        padding: 10px;
        background-color: color-mix(in srgb, var(--accent-color), transparent 85%);
        border-radius: 10px;
        transition: 1s;
    }

    .professional-experience-description-box:hover {
        cursor: pointer;
        box-shadow: 0 5px 25px 3px color-mix(in srgb, var(--accent-color), transparent 50%);
        transition: 1s;
    }
    
    .professional-experience-description-text-area {
        display: flex;
        text-align: left;
        flex-direction: column;
    }

    .professional-experience-description-image {
        height: auto;
        width: 100%;
    }

    .professional-experience-description-text {
        color: var(--text-color);
        margin-bottom: 8px;
    }

    .professional-experience-description-text-location {
        font-size: 18px;
        font-weight: 500;
        color: var(--accent-color);
        margin-bottom: 5px;
    }

    .professional-experience-description-text-title {
        font-size: 18px;
        font-weight: 400;
        color: var(--accent-color);
        margin-bottom: 15px;
    }

    .professional-experience-description-text-title__job-responsibilities {
        margin-bottom: 8px;
    }

    .column {
        padding: 1.5rem;
    }

    .round-tag-column__professional-experience {
        margin: 0 10px 0 12px;
    }

    .tag__professional-experience {
        padding: 6px 10px 6px 10px;
        font-size: 12px;
    }

    .more-information-button {
        color: var(--dark-text-color);
        animation: moveUpAndDown 2s ease-in-out infinite;

    }

    @media screen and (max-width: 768px) {
        .professional-experience-title-card {
            margin-bottom: 15px;
            height: 40px;
            width: 100%;
        }
        .professional-experience-title {
            font-size: var(--mobile-text-font-size);
        }
        .professional-experience-description-box {
            width: 100%;
        }
        .professional-experience-description-text-area {
            text-align: center;
        }
        .professional-experience-description-image {
            height: 200px;
            width: auto;
        }
        .professional-experience-description-text {
            font-size: var(--mobile-text-font-size);
        }
        .professional-experience-description-text-title {
            font-size: var(--mobile-subtitle-font-size);
        }
        .round-tag-column__professional-experience {
            justify-content: center;
            margin: 0 5px 0 5px;
        }
        .tag__professional-experience {
            font-size: 10px;
        }
    }
    
    @keyframes moveUpAndDown {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-2px);
        }
        100% {
            transform: translateY(0);
        }
    }
  </style>
  